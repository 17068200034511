const ArrowDownIcon = ({ color = '#FFFFFF' }: { color?: string }) => {
    return (
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.2071 0.792885C1.81657 0.402365 1.18341 0.402372 0.792885 0.792901C0.402365 1.18343 0.402372 1.81659 0.792901 2.20711L6.28355 7.69764C6.28664 7.70081 6.28976 7.70397 6.2929 7.70711C6.48894 7.90315 6.74613 8.00078 7.00307 8C7.25801 7.99925 7.51272 7.90162 7.70724 7.7071C7.71037 7.70396 7.71348 7.70081 7.71656 7.69765L13.2076 2.2065C13.5981 1.81597 13.5981 1.1828 13.2076 0.792282C12.8171 0.401762 12.1839 0.401769 11.7934 0.792297L7.00005 5.58573L2.2071 0.792885Z"
                fill={color}
            />
        </svg>
    );
};

export default ArrowDownIcon;
