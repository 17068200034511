const LanguagesIcon = ({ color = 'white', width = '43', style }: { color?: string; width?: string; style?: Object }) => {
    return (
        <svg width={width} height="28" viewBox="0 0 43 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <g clipPath="url(#clip0_7108_218)">
                <path
                    d="M42.8343 7.94155V20.0681C42.7674 20.414 42.7105 20.7632 42.6316 21.1069C41.699 25.1965 38.2199 27.9846 33.9868 27.9922C25.8811 28.0054 17.7754 27.9977 9.66976 27.9922C9.24678 27.9922 8.82051 27.9681 8.40301 27.9079C3.99678 27.2632 0.848536 23.6344 0.838674 19.1858C0.831003 15.7311 0.833195 12.2764 0.841961 8.82164C0.843057 8.34547 0.868261 7.86383 0.946063 7.39422C1.65176 3.12948 5.19669 0.0458535 9.54703 0.0261498C17.7349 -0.0110682 25.9227 -0.00559496 34.1106 0.0239605C38.1366 0.0392856 41.516 2.69381 42.5439 6.54587C42.6666 7.00453 42.7379 7.47632 42.8343 7.94264V7.94155ZM21.8562 24.4926H22.288C26.1704 24.4926 30.0528 24.4948 33.9342 24.4926C37.0408 24.4904 39.3179 22.2256 39.3255 19.119C39.3343 15.7048 39.3343 12.2917 39.3255 8.87747C39.3168 5.77743 37.0331 3.51917 33.921 3.51808C30.0528 3.51589 26.1835 3.51808 22.3153 3.51808H21.8562V24.4948V24.4926ZM13.3878 18.0233C13.539 18.6855 13.6738 19.3106 13.8251 19.9323C14.0124 20.7062 14.6272 21.12 15.3351 20.9613C16.0221 20.808 16.3838 20.1447 16.2051 19.3598C15.8063 17.6051 15.4085 15.8504 15.0041 14.0978C14.6064 12.3716 14.2316 10.6388 13.7911 8.92345C13.4777 7.69854 12.4904 7.00562 11.2477 7.02314C10.0938 7.03956 9.08132 7.81129 8.80408 8.96395C7.96579 12.4493 7.14722 15.939 6.33194 19.4299C6.161 20.1611 6.54672 20.8037 7.20201 20.9591C7.89456 21.1233 8.52136 20.7227 8.70874 19.9838C8.85229 19.4156 8.97064 18.841 9.12734 18.2761C9.15583 18.1732 9.31143 18.0353 9.40896 18.0342C10.7195 18.0178 12.0312 18.0233 13.3867 18.0233H13.3878Z"
                    fill={color}
                />
                <path
                    d="M30.5205 16.3186C31.6404 15.3651 32.8699 12.4698 32.8151 10.9548C32.6573 10.9548 32.4973 10.9548 32.3363 10.9548C30.1501 10.9548 27.9629 10.9625 25.7768 10.9449C25.4897 10.9428 25.173 10.8694 24.9253 10.7304C24.5177 10.5016 24.3763 9.99698 24.51 9.54708C24.6514 9.072 25.024 8.77097 25.5412 8.7655C26.6896 8.75346 27.8369 8.76003 28.9853 8.76003C29.1332 8.76003 29.2812 8.76003 29.4631 8.76003C29.5091 8.20395 29.382 7.59642 29.9211 7.24941C30.1348 7.11149 30.4296 7.03486 30.6849 7.03924C31.3785 7.05238 31.6141 7.46506 31.7018 8.76003C31.8399 8.76003 31.9834 8.76003 32.127 8.76003C33.2885 8.76003 34.4501 8.75346 35.6116 8.7655C36.1584 8.77097 36.5836 9.12345 36.6778 9.60838C36.7797 10.1349 36.5507 10.6373 36.0883 10.8366C35.9174 10.9099 35.7212 10.9307 35.5338 10.9559C35.4001 10.9734 35.2632 10.9592 35.2095 10.9592C34.8643 12.1797 34.5849 13.3696 34.1849 14.519C33.7805 15.6837 33.111 16.7203 32.2771 17.6945C33.0562 18.2025 33.8813 18.4914 34.7481 18.6732C35.0681 18.7399 35.3947 18.7804 35.7179 18.8308C36.3798 18.9337 36.7589 19.4142 36.6888 20.0645C36.6241 20.6588 36.1157 21.0518 35.4604 20.9916C33.843 20.8406 32.3341 20.3545 30.9753 19.4569C30.6915 19.2697 30.5074 19.2774 30.2268 19.4613C28.8527 20.3611 27.3306 20.8471 25.6979 20.9927C25.0503 21.0496 24.5429 20.6578 24.4651 20.0601C24.3851 19.4438 24.7906 18.9184 25.4513 18.8341C26.6556 18.6819 27.8073 18.3842 28.8998 17.7121C28.8122 17.6004 28.7256 17.5041 28.6555 17.3968C28.2577 16.7947 27.8369 16.2047 27.4764 15.5808C27.1674 15.0466 27.3777 14.4226 27.895 14.1391C28.4144 13.8545 29.0269 14.0198 29.3612 14.5343C29.7447 15.1265 30.1293 15.7176 30.5183 16.3175L30.5205 16.3186Z"
                    fill={color}
                />
                <path
                    d="M12.8269 15.5494H9.75977C10.2452 13.5024 10.723 11.4871 11.2018 9.47079C11.2687 9.46751 11.3344 9.46422 11.4013 9.46094C11.9251 11.4641 12.3261 13.498 12.8269 15.5494Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_7108_218">
                    <rect width="42" height="28" fill={color} transform="translate(0.833984)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LanguagesIcon;
