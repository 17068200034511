import { createApi } from '@reduxjs/toolkit/query/react';
import { httBaseQueryWithRetry } from 'services/rtk/baseQuery';

export const legacyApi = createApi({
    reducerPath: 'legacyApi',
    baseQuery: httBaseQueryWithRetry,
    endpoints: (builder) => ({
        getLegacyById: builder.query<any, any>({
            query: (id: string) => ({ url: `/core/legacy/opened/${id}`, method: 'get' })
        }),
        getSharedLegacies: builder.query<any, any>({
            query: () => ({ url: `/core/legacy/shared?limit=${1000000000000}`, method: 'get' })
        })
    })
});

export const { useGetLegacyByIdQuery, useGetSharedLegaciesQuery } = legacyApi;
