import { Box, Modal, Stack, useMediaQuery, useTheme } from '@mui/material';
import useConfig from 'hooks/useConfig';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'ui-component/CustomButton';
import CustomLink from 'ui-component/CustomLink';
import LegacyModalOrnament from 'ui-component/Icons/LegacyModalOrnament';
import Text from 'ui-component/Text/Text';
import CloseIcon from '@mui/icons-material/Close';

const LegacyModal = ({ children, buttonStyle }: { children: ReactElement; buttonStyle?: Object }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isPhone = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { REACT_APP_FIREBASE_ASSETS_BASE_URL } = useConfig();
    const style = {
        position: 'absolute',
        maxWidth: '1000px',
        maxHeight: '918px',
        top: '70%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        marginBottom: '20px',
        bgcolor: '#FFF',
        borderRadius: '10px',
        boxShadow: 24,
        p: 4,
        [theme.breakpoints.down('md')]: { maxWidth: '400px', width: '100%', top: '65%', transform: 'translate(-50%, -50%)', padding: 2 }
    };

    const buttons = [
        { title: t('homeV2.description.legacyModal.button1'), link: 'https://1.besoul.app/create-a-digital-legacy' },
        { title: t('homeV2.description.legacyModal.button2'), link: 'https://1.besoul.app/legacy-of-secrets' },
        { title: t('homeV2.description.legacyModal.button3'), link: 'https://1.besoul.app/be-soul-create-a-voice-note-legacy' },
        { title: t('homeV2.description.legacyModal.button4'), link: 'https://1.besoul.app/time-capsule' }
    ];
    return (
        <>
            <CustomButton sx={buttonStyle} onClick={handleOpen}>
                {children}
            </CustomButton>
            <Modal sx={{ overflowY: 'auto', height: '100%' }} open={open} onClose={handleClose}>
                <Box sx={style}>
                    <Box position="relative">
                        <Box position="absolute" right={'-10%'} top={-80} onClick={handleClose}>
                            <CustomButton>
                                <CloseIcon sx={{ fill: '#FEFEFE', width: '30px', height: '30px' }} />
                            </CustomButton>
                        </Box>
                    </Box>
                    <Box
                        width={{ xs: '100%' }}
                        height={{ xs: '157px', md: '291px', lg: '391px' }}
                        maxWidth={{ xs: '361px', md: '899px', lg: '899px' }}
                        maxHeight={{ xs: '157px', md: '291px', lg: '391px' }}
                        sx={{
                            margin: '0 auto',
                            borderRadius: '10px',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: `url(${REACT_APP_FIREBASE_ASSETS_BASE_URL}/Modal%20legados.webp)`
                        }}
                    />
                    <Text
                        preset={{ xs: 'titleMedium', md: 'headlineMedium' }}
                        textColor={theme.palette.grey[700]}
                        fontFamily="Merriweather"
                        fontWeight={700}
                        sx={{
                            xs: { marginTop: '10px', textAlign: 'center', fontSize: '20px' },
                            md: { textAlign: 'center', fontSize: '40px', width: '100%', marginX: 0 }
                        }}
                    >
                        {t('homeV2.description.legacyModal.title')}
                    </Text>
                    <Text
                        preset={{ xs: 'titleMedium', md: 'headlineMedium' }}
                        textColor={theme.palette.grey[700]}
                        fontFamily="Merriweather"
                        fontWeight={400}
                        sx={{
                            xs: { marginTop: '10px', textAlign: 'center', fontSize: '14px', fontStyle: 'italic' },
                            md: { textAlign: 'center', fontSize: '18px', width: '100%', marginX: 0 }
                        }}
                    >
                        {t('homeV2.description.legacyModal.text')}
                    </Text>

                    <Box sx={{ margin: '20px auto', width: 'max-content' }}>
                        <LegacyModalOrnament width={isPhone ? '194px' : undefined} />
                    </Box>

                    <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        flexWrap="wrap"
                        width={{ xs: '274px', md: '695px' }}
                        gap={'20px'}
                        margin="20px auto"
                    >
                        {buttons.map((button, index) => {
                            if (isPhone) {
                                <CustomLink
                                    key={index}
                                    to={button.link}
                                    sx={{
                                        borderRadius: '12px',
                                        backgroundColor: '#ECE5E3',
                                        padding: '10px',
                                        width: '337px',
                                        [theme.breakpoints.up('md')]: { padding: '16px' }
                                    }}
                                >
                                    <Text
                                        preset={{ xs: 'titleMedium', md: 'headlineMedium' }}
                                        textColor={theme.palette.grey[700]}
                                        fontFamily="Merriweather"
                                        fontWeight={700}
                                        sx={{
                                            xs: { textAlign: 'center', fontSize: '12px' },
                                            md: { fontSize: '16px', width: '100%', marginX: 0 }
                                        }}
                                    >
                                        {button.title}
                                    </Text>
                                </CustomLink>;
                            }
                            return (
                                <CustomLink
                                    key={index}
                                    to={button.link}
                                    sx={{
                                        borderRadius: '12px',
                                        backgroundColor: '#ECE5E3',
                                        padding: '10px',
                                        width: '337px',
                                        [theme.breakpoints.up('md')]: { padding: '16px' }
                                    }}
                                    target="_blank"
                                >
                                    <Text
                                        preset={{ xs: 'titleMedium', md: 'headlineMedium' }}
                                        textColor={theme.palette.grey[700]}
                                        fontFamily="Merriweather"
                                        fontWeight={700}
                                        sx={{
                                            xs: { textAlign: 'center', fontSize: '12px' },
                                            md: { fontSize: '16px', width: '100%', marginX: 0 }
                                        }}
                                    >
                                        {button.title}
                                    </Text>
                                </CustomLink>
                            );
                        })}
                    </Stack>
                </Box>
            </Modal>
        </>
    );
};

export default LegacyModal;
