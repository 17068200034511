import { createApi } from '@reduxjs/toolkit/query/react';
import { httBaseQueryWithRetry } from 'services/rtk/baseQuery';

export interface OtpAuthProps {
    method: 'email' | 'phone' | 'test';
    code?: string;
    email?: string;
    phone?: {
        cca2: string;
        code: string;
        number: string;
    };
}

export interface CreateUserProps {
    uid: string;
    email: string;
    name: string;
    lastName: string;
    phone?: {
        cca2: string;
        code: string;
        number: string;
    };
}

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: httBaseQueryWithRetry,
    endpoints: (builder) => ({
        otpAuth: builder.mutation<any, any>({
            query: (data: OtpAuthProps) => ({
                url: '/core/user/otp-auth',
                method: 'post',
                data
            })
        }),
        generateRegister: builder.mutation<any, { email: string; language: string }>({
            query: (data) => ({
                url: '/core/user/otp-auth/generate-register',
                method: 'post',
                data,
                headers: {
                    'accept-language': data.language
                }
            })
        }),
        LoginWithFirebaseToken: builder.mutation<any, { token?: string; firebaseToken: string | null }>({
            query: (data) => ({
                url: '/core/user/login',
                method: 'post',
                data
            })
        }),
        createUser: builder.mutation<any, CreateUserProps>({
            query: (data) => ({
                url: '/core/user/create',
                method: 'post',
                data
            })
        })
    })
});

export const { useOtpAuthMutation, useGenerateRegisterMutation, useLoginWithFirebaseTokenMutation, useCreateUserMutation } = usersApi;
