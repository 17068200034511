const legacy = {
    sharedLegacy: {
        step1: {
            title: ' has shared a special legacy with you in Be Soul',
            subtitle: 'Enter your first and surname',
            input1: { label: 'Name' },
            input2: { label: 'Surname' },
            button1: 'Continue',
            button2: 'Skip'
        },
        step2: {
            title: 'Verify OTP',
            description: 'Please enter the OTP code sent to your email'
        }
    },
    legacyById: {
        downloadApp: {
            title: {
                1: 'Download the ',
                2: 'Be Soul App',
                3: ' and start creating unique legacies to share with your loved ones'
            },
            description:
                'To access the content of this legacy from the app, make sure you log in with the same email that it was shared with you.',
            shareWithMe: {
                title: 'Share with me',
                button: 'See all',
                emptyState: {
                    1: 'No legacies have been shared with you yet.',
                    2: 'When someone creates one, you will see it here.'
                }
            },
            unauthorized:
                'This legacy is no longer available. The owner may have deleted it or removed you from the list of people with shared access.'
        },
        legacyInfo: {
            createdBy: 'Created by: ',
            createdAt: 'Creation date: ',
            legacyFiles: {
                photosAndVideos: 'Photos and videos',
                files: 'Files',
                audios: 'Audios',
                emptyState: 'The creator of this legacy has not yet uploaded content, but he will share some soon, stay tuned!'
            },
            liberationType: {
                instant: 'Instant',
                scheduled: 'Scheduled',
                postMortem: 'Post Mortem'
            },
            liberation: 'Release'
        }
    },
    feed: {
        unAvailableLegacy: {
            timeCapsule: {
                title: "You can't open this legacy yet",
                description: 'You can release it on '
            },
            postMortem: {
                title: 'How to unlock this Post-Mortem legacy?',
                description:
                    'Unlock this legacy by uploading the death certificate in the application or wait for other heirs to upload the death certificate to unlock the legacy.'
            }
        }
    }
};

export default legacy;
