// import { lazy } from 'react';

import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import SharedLegacy from 'views/pages/Legacies/PublicPages/SharedLegacy';
// import GuestGuard from 'utils/route-guard/GuestGuard';

// project imports
// import AuthGuard from 'utils/route-guard/AuthGuard';
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')));
const Calm = Loadable(lazy(() => import('views/pages/Calm')));
const ComingSoon = Loadable(lazy(() => import('views/pages/ComingSoon')));
const DigitalLegacy = Loadable(lazy(() => import('views/pages/DigitalLegacy')));
// const Home = Loadable(lazy(() => import('views/pages/Home')));
const Home = Loadable(lazy(() => import('views/pages/HomeV2')));
const Memorials = Loadable(lazy(() => import('views/pages/Memorials')));
const Plans = Loadable(lazy(() => import('views/pages/Plans')));
const PrivacyPolicy = Loadable(lazy(() => import('views/pages/PrivacyPolicy')));
const Support = Loadable(lazy(() => import('views/pages/Support')));
const TermsAndConditions = Loadable(lazy(() => import('views/pages/TermsAndConditions')));
const PublishedMemorial = Loadable(lazy(() => import('views/pages/AuthMemorials/PublishedMemorial')));
const DeleteAccount = Loadable(lazy(() => import('views/pages/Account/DeleteAccount')));
// import Loadable from 'ui-component/Loadable';

// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

export const MainRoutes = {
    path: '/',
    element: (
        // <GuestGuard>
        <MainLayout isLanding />
        // </GuestGuard>
    ),
    children: [
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/digital-legacy',
            element: <DigitalLegacy />
        },
        {
            path: '/memorials',
            element: <Memorials />
        },
        {
            path: '/calm',
            element: <Calm />
        },
        {
            path: '/coming-soon',
            element: <ComingSoon />
        },
        {
            path: '/contact',
            element: <Support />
        },
        {
            path: '/pricing',
            element: <Plans />
        },
        {
            path: '/delete-account',
            element: <DeleteAccount />
        }
        // {
        //     path: '/app/memorial/preview/guest/:memorialId',
        //     element: <PreviewMemorial />
        // }
        // {
        //     path: '/sample-page',
        //     element: <SamplePage />
        // }
    ]
};

export const PublicMemorialPreview = {
    path: '/',
    children: [
        {
            path: '/app/memorial/:memorialId',
            element: <PublishedMemorial />
        }
    ]
};

export const PublicLegacyRoutes = {
    path: '/',
    children: [
        {
            path: '/app/shared/legacy/:legacyId',
            element: <SharedLegacy />
        }
    ]
};

export const TyCAndPdP = {
    path: '/',
    element: <MainLayout isLanding />,
    children: [
        {
            path: '/terms-conditions',
            element: <TermsAndConditions />
        },
        {
            path: '/policy-privacy',
            element: <PrivacyPolicy />
        }
    ]
};
